import * as React from "react"
//import SliderHome from "../slider/SliderHome"
import loadable from '@loadable/component'
const SliderHome = loadable(()=>import('../slider/SliderHome'))
const Header = (props) => {

    return (
        props.ishome && <SliderHome />
    )

}

export default Header