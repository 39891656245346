import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useInView } from 'react-intersection-observer';
import './risorse.scss'

const Risorse = () => {

    const data = useStaticQuery(graphql`
    {    
      risorse: file(relativePath: { eq: "risorse.svg" }) {
                relativePath
                publicURL
            }              
      pot: file(relativePath: { eq: "pot.svg" }) {
                      relativePath
                      publicURL
                  }   
      pentola: file(relativePath: { eq: "pentola.svg" }) {
                      relativePath
                      publicURL
                  }  
            allRisorseYaml {

                        nodes {
                            lang
                            risorse{
                            nome
                            url
                            }
                        }
                        }
    }
  `)

    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
   

    return (
        <>
            <div class="anchor" ></div>
            <section
                className={`presentazione ${inView2 ? 'open' : ''}`}
                ref={ref2}
                id="informazioni"
            >

                <div className="box-left">
                    <h1>JamPot</h1>
                    <p>Le tecnologie che guidano internet sono in continuo mutamento, alcune sono mode, altre invece propongono soluzioni per risolvere problemi, migliorare l'accesso alle informazioni.
                        <br />Semplificando un po', <a className="in-evidenza link" href="https://jamstack.org/" target="_blank" rel="noopener">Jamstack</a> è il metodo per la progettazione di risorse per internet che utilizza queste tecnologie mettendo al centro i due aspetti più importanti: <br /> <strong>gli utenti e i dati.</strong>
                    </p>
                    <p>
                        Gli utenti devono accedere alle risorse in modo chiaro e coinvolgente.
                        <br />I dati devono essere slegati dalla presentazione e ogni interfaccia può collegarsi alle stesse informazioni per creare diverse modalità di accesso (Website, PWA...)

                    </p>
                    <p>
                        Jampot Raccoglie e analizza le risorse che sfruttano l'universo delle tecnologie <a className="in-evidenza link" href="https://jamstack.org/" target="_blank" rel="noopener">Jamstack</a> per migliorare prestazioni ed esperienza utente.
                    </p>
                </div>
                <div className="box-right">
                    <img src={data.pentola.publicURL} alt="pentola" width="420" height="336" />
                </div>
            </section>

            <section
                className={`risorse ${inView ? 'open' : ''}`}
                ref={ref}>
                <div className="box-left">
                    <img src={data.risorse.publicURL} alt="strumenti" width="360" height="312"/>
                </div>
                <div className="box-right">
                    <h1>Cosa facciamo</h1>
                    <p>
                        <b>Una cosa è certa:</b> Sappiamo che non basta "guardare" un sito per sapere se è ben
                        fatto...sarebbe come giudicare la <span class="in-evidenza">marmellata</span>  solo dal barattolo.
                        <br />Noi andiamo oltre, leggiamo gli ingredienti, apriamo, assaggiamo... e diamo la nostra personale opinione (in percentuale).
                        <br /> Nelle nostre valutazioni teniamo di conto di tre aspetti fondamentali:
                        <dl>
                            <dt>La Tecnologia</dt>
                            <dd>E' importante capire le tecnologie utilizzate,
                                come vengono utilizzate e il modo in cui valorizzano (o meno...) il progetto.</dd>
                            <dt>Il design grafico e la UI</dt>
                            <dd>Funzione e forma fanno parte dello stesso fenomeno. Analizziamo gli aspetti
                                legati alla presentazione dei contenuti e all'interfaccia, secondo criteri di progettazione dello spazio
                                e dell'interazione, alla ricerca di equlibrio tra chiarezza e linearità, da una parte ed estro, innovazione e intrattenimento, dall'altra.

                            </dd>
                            <dt>La Velocità</dt>
                            <dd>
                                "nuda e cruda"...la testiamo con <a href="https://gtmetrix.com/" target="_blank" rel="noopener">GTmetrix</a>,
                                riportando in modo "sensato" gli aspetti, se ci sono, che rendono lento l'accesso ai contenuti.
                            </dd>
                        </dl>
                        Vuoi mettere alla prova i tuoi siti web, studiare le tecnologie utilizzate per creare una particolare risorsa online?
                        <br />Ecco gli strumenti del mestiere, quelli essenziali!
                    </p>
                    <ul>
                        {data.allRisorseYaml.nodes[0].risorse.map((item) => {
                            return <li>
                                <img src={data.pot.publicURL} alt="pot" width="48" height="48"/>
                                <a href={item.url} target="_blank" rel="noopener">{item.nome}</a>
                            </li>
                        })}</ul>
                    <div class="vai-a-risorse">Accedi alle altre <Link to="risorse">risorse >>></Link></div>
                </div>

            </section>
        </>
    )
}

export default Risorse