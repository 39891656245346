import * as React from "react"
import Navbar from "../components/navbar/Navbar"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import '../assets/scss/global.scss'
import CookieConsent from "react-cookie-consent"
import { Link, useStaticQuery, graphql } from "gatsby"
import ScrollToTop from "react-scroll-to-top";



const LayoutHome = ({ children }, location) => {

  const data = useStaticQuery(graphql`
  {    
         
    pot: file(relativePath: { eq: "pot.svg" }) {
                    relativePath
                    publicURL
                }   }
`)
  return (
    <>
      <ScrollToTop smooth />
      <div className="claim">
        Il primo aggregatore italiano di siti <a href="https://jamstack.org/" target="_blank" rel="noopener">fatti bene!</a>
        <img src={data.pot.publicURL} alt="poticon" width="30" height="30" /></div>


      <Navbar ishome location={location} />

      <div className="maincontainer">
        <Header ishome />
        {children}

      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Ho capito"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#E20E57" }}
        buttonStyle={{ color: "#000", fontSize: "13px", background: "#fff" }}
        expires={150}
      >
        Questo sito utilizza cookies per migliorare l'esperienza di navigazione
        <Link to="/privacy">
          <span style={{ fontSize: "16px", paddingLeft: "10px", color: " white", textDecoration: "underline" }}>Leggi il documento di Privacy</span>
        </Link>
      </CookieConsent>
    </>
  )
}

export default LayoutHome