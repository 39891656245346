import * as React from "react"
import loadable from '@loadable/component'
import { Helmet } from "react-helmet"
import LayoutHome from "../layouts/LayoutHome"
import Contributors from "../components/contributors/contributors"
//import Archivio from "../components/archivio/Archivio"
import Risorse from "../components/risorse/Risorse"
import FormHome from "../components/formHome/FormHome"
const Archivio = loadable(() => import('../components/archivio/Archivio'))


const IndexPage = ({ location }) => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jampot Homepage</title>
        <meta name="description" content="Il primo aggregatore italiano di risorse Jamstack" />

      </Helmet>
      <LayoutHome>
        <Archivio />
        <Risorse />
        <FormHome />
        <Contributors />
      </LayoutHome>

    </>
  )
}

export default IndexPage
