import * as React from "react"
import { motion, useAnimation } from 'framer-motion';
import { useStaticQuery, graphql, Link } from "gatsby"
import { useInView } from 'react-intersection-observer';
import './formhome.scss'
import { Checkbox } from "@material-ui/core";

const FormHome = () => {
    const { useEffect } = React;
    const data = useStaticQuery(graphql`
        {
          furgone: file(relativePath: { eq: "furgone.svg" }) {
                          relativePath
                          publicURL
                      }                        
        }
      `)
    const controls = useAnimation();
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            controls.start('animate');
        }
        if (!inView) {
            controls.start('initial');
        }
    }, [controls, inView]);

    const variants = {
        initial: { opacity: 0, x: -150, rotate: 0 },
        animate: {
            opacity: 1,
            x: 0,
            rotate: 360,
            transition: {
                type: "spring",
                stiffness: 160,
                damping: 20,
                duration: 2,
                delay: 0.2
            }
        }
    };

    return (
        <div className="formhome" id="joinus" ref={ref} >
            <div className="formhome-sx">
                <h1>Siti web di prima qualità!</h1>

                <p>
                    Entra a far parte di Jampot.it segnalando risorse interessanti create con Jamstack,
                    verrai segnalato nella scheda di analisi con il link al tuo sito web
                </p>
                <motion.img
                    className="imageform"
                    variants={variants}
                    initial={"initial"}
                    animate={controls}
                    loading="eager"
                    fadeIn={false}
                    src={data.furgone.publicURL} alt="imageform" width="350" height="350"/>
                    

            </div>
            <div className="formhome-dx">

                <form method="POST" name="segnalazione" action="/thanks" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="form-name" value="segnalazione" />
                    <label htmlFor="nome">
                        <input placeholder="nome" type="text" name="nome" id="nome" required />
                    </label>
                    <label htmlFor="url">
                        <input placeholder="Il tuo sito personale o aziendale" type="url" name="url" id="sito" />
                    </label>
                    <label htmlFor="email">
                        <input placeholder="email" type="text" name="email" id="email" required />
                    </label>
                    <label htmlFor="url">
                        <input placeholder="Il sito che ci segnali" type="url" name="url" id="sito-segnalato" required />
                    </label>
                    <label htmlFor="messaggio">
                        <textarea placeholder="messaggio" name="messaggio" id="messaggio" required />
                    </label>
                    <label htmlFor="privacy">

                        <input type="checkbox" placeholder="privacy" name="privacy" id="privacy" required />
                        <span>Accettazione della <Link to="/privacy"> Privacy</Link></span>
                    </label>

                    <label htmlFor="submit">

                        <input type="submit" value="invia" name="submit"  />
                    </label>
                </form>
            </div>

        </div>

    )

}


export default FormHome